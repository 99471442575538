//==
//== Site header
//== ======================================= ==//

$(function () {
    var $header = $('.header-section');

    var $wnd = $(window);
    $wnd.on('scroll', function () {
        $header.toggleClass('isFixed', $wnd.scrollTop() > 400);
    }).trigger('scroll');
});
