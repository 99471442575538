//==
//== Scroll to
//== ======================================= ==//

(function () {
    $('[data-scrollto]').on('click', function (event) {
        event.preventDefault();
        var id  = $(this).attr('href');
        if (!id) {
            id = $(this).data('scrollto');
        }
        var top = $(id).offset().top - 50;
        $('body,html').animate({ scrollTop: top }, 1000);
    });
})();
