// var use_preloader = document.getElementsByClassName('preloader-page');
// use_preloader && jQuery(document).ready(function (e) {
//     setTimeout(function () {
//         e('body').attr('preloaderPage', 'end');
//         e('.preloader-page').remove();
//     }, 700);
// });

$('form').each(function(){
    $(this).prepend('<input type="hidden" name="nobot" value="true">');
});

$('.upload').click(function(){
    $('#addfile').trigger('click');
});
$('#addfile').change(function(){
    var tmp = $('#addfile').val();
    $('.upload span').html(tmp);
    $('.upload span').css({'color':'#FB901C', 'font-weight':'bold'});
});


// доп. параметры в заявке
$('.form-order-ext').each(function () {
    let $params    = $(this);
    let $form      = $params.closest('form');
    let $dops      = $form.find('input[name="dops"]');
    let $container = $params.find('.form-order-ext__params');
    let $toggle    = $params.find('.form-order-ext__toggle > a');

    let ext = !!+$dops.val();

    if (ext) {
        $container.slideDown();
    }

    $toggle.on('click', function (e) {
        e.preventDefault();
        $container.slideToggle();
        ext = !ext;
        $dops.val(+ext);
    });
});

$('.user-content').find('table').not('table table').each(function(index, el){
    $(el).wrap('<diV class="table-responsive"></diV>');
});
