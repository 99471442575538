//==
//== Carousels
//== ======================================= ==//

(function () {
    let defaultOwlSettings = {
        dots           : false,
        loop           : true,
        margin         : 10,
        responsiveClass: true,
        nav            : true,
        responsive     : {
            0   : {
                items: 1,
            },
            480 : {
                items: 2,
            },
            576 : {
                items: 3,
            },
            992 : {
                items: 4,
            },
            1200: {
                items: 6,
                loop : false,
            },
        },
    };

    $('.js-news-carousel').owlCarousel(defaultOwlSettings);
    $('.carousel-6x').add('.js-projects-carousel').owlCarousel(defaultOwlSettings);
})();
