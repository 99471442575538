//==
//== Projects table
//== ======================================= ==//

(function () {

    const $projects = $('#projects');

    if (!$projects.length) return;

    const T_Number        = 0;
    const T_Purpose       = 1;
    const T_Name          = 2;
    const T_SnowRegion    = 3;
    const T_WindRegion    = 4;
    const T_ColdWarm      = 5;
    const T_Type          = 6;
    const T_Width         = 7;
    const T_Length        = 8;
    const T_Area          = 9;
    const T_Height        = 10;
    const T_Weight        = 11;
    const T_PP            = 12;
    const T_ProjectFiles  = 13;
    const T_EstimateFiles = 14;

    const T_COLD = 1;
    const T_WARM = 2;

    const TYPES = {
        'о' : 1,
        'оо': 2,
        'оп': 3,
        'п' : 4,
        'к' : 5,
        'н' : 6,
    };

    class Parser {
        /**
         * @param {HTMLElement} table
         */
        constructor(table) {
            this.source         = table;
            this.rows           = [];
            this.purposeList    = [{ id: 0, text: '---' }];
            this.snowRegionList = [{ id: 0, text: '---' }];
            this.windRegionList = [{ id: 0, text: '---' }];

            for (let i = 1; i <= 8; i++) this.snowRegionList.push({ id: i, text: i });
            for (let i = 1; i <= 8; i++) this.windRegionList.push({ id: i, text: i });

            this.parse();
        }

        parse() {
            $(this.source).find('tbody > tr').each((index, elem) => {
                const $cells = $('td', elem);

                let purposeItem = this.purposeParseRaw($.trim($cells.eq(T_Purpose).html()));

                let coldWarm      = $.trim($cells.eq(T_ColdWarm).html()).toLowerCase();
                let coldWarmValue = coldWarm === 'теплое' || coldWarm === 'тёплое'
                    ? T_WARM
                    : (coldWarm === 'холодное' ? T_COLD : 999);

                let typeRaw   = $.trim($cells.eq(T_Type).html());
                let typeValue = TYPES.hasOwnProperty(typeRaw) ? TYPES[typeRaw] : 999;

                let row = {
                    PNumber       : $cells.eq(T_Number).html(),
                    PPurpose      : purposeItem.id,
                    PName         : $cells.eq(T_Name).html(),
                    PSnowRegion   : +$cells.eq(T_SnowRegion).html(),
                    PWindRegion   : +$cells.eq(T_WindRegion).html(),
                    PColdWarm     : coldWarmValue,
                    PType         : typeValue,
                    PWidth        : $cells.eq(T_Width).html(),
                    PLength       : $cells.eq(T_Length).html(),
                    PArea         : $cells.eq(T_Area).html(),
                    PHeight       : $cells.eq(T_Height).html(),
                    PWeight       : $cells.eq(T_Weight).html(),
                    PPP           : $cells.eq(T_PP).html(),
                    PProjectFiles : $cells.eq(T_ProjectFiles).html(),
                    PEstimateFiles: $cells.eq(T_EstimateFiles).html(),
                };
                this.rows.push(row);
            });
        }

        purposeParseRaw(rawString) {
            let text        = $.trim(rawString);
            let purposeItem = this.purposeCheckExists(text);
            if (purposeItem === false) {
                purposeItem = {
                    id  : this.purposeList.length + 1,
                    text: text,
                };
                this.purposeList.push(purposeItem);
            }

            return purposeItem;
            // console.log(rawString);
            // let words = rawString.split(' ').filter(word => $.trim(word).length > 2);
            // console.log(words);
            // words.forEach(item => {
            //     if (this.purposeCheckExists(item) === false) {
            //         this.purposeList.push({
            //             id  : this.purposeList.length + 1,
            //             text: item,
            //         });
            //     } else {
            //
            //     }
            // });
        }

        purposeCheckExists(text) {
            let exists = false;

            this.purposeList.forEach(item => {
                if (item.text === text) {
                    exists = item;
                }
            });

            return exists;
        }

        getRows() { return [...this.rows]; }

        getPurposeList() { return [...this.purposeList]; }

    }

    const parser = new Parser(document.getElementById('projects'));
    $projects.remove();

    let controller = {
        loadData: function (filter) {
            return $.grep(this.rows, function (row) {
                return (!filter.PNumber || row.PNumber.indexOf(filter.PNumber) > -1)
                    && (!filter.PPurpose || row.PPurpose === filter.PPurpose)
                    && (!filter.PName || row.PName.indexOf(filter.PName) > -1)
                    && (!filter.PSnowRegion || row.PSnowRegion === filter.PSnowRegion)
                    && (!filter.PWindRegion || row.PWindRegion === filter.PWindRegion)
                    && (!filter.PColdWarm || row.PColdWarm === filter.PColdWarm)
                    && (!filter.PType || row.PType === filter.PType)
                    ;
            });
        },

        rows          : parser.getRows(),
        purposeList   : parser.getPurposeList(),
        snowRegionList: [...parser.snowRegionList],
        windRegionList: [...parser.windRegionList],
        coldWarmList  : [
            { id: 0, text: '---' },
            { id: T_COLD, text: 'Холодное' },
            { id: T_WARM, text: 'Теплое' },
        ],
        typesList     : [
            { id: 0, code: 0, text: '---' },
            { id: 1, code: 'о', text: 'о - одиночное' },
            { id: 2, code: 'оо', text: 'оо - двойное' },
            { id: 3, code: 'оп', text: 'оп - одиночное с пристройкой' },
            { id: 4, code: 'п', text: 'п - пристройка к существующему' },
            { id: 5, code: 'к', text: 'к - комплекс зданий' },
            { id: 6, code: 'н', text: 'н - навес' },
        ],
    };

    let fields = [
        { name: 'PNumber', type: 'text', title: 'Заказ', width: 50 },
        {
            name      : 'PPurpose',
            type      : 'select',
            items     : controller.purposeList,
            valueField: 'id',
            textField : 'text',
            title     : 'Назначение',
            width     : 120,
        },
        { name: 'PName', type: 'text', title: 'Проект/Заказчик', width: 'auto' },
        {
            name      : 'PSnowRegion',
            type      : 'select',
            items     : controller.snowRegionList,
            valueField: 'id',
            textField : 'text',
            title     : 'Снеговой р-н',
            width     : 60,
        },
        {
            name      : 'PWindRegion',
            type      : 'select',
            items     : controller.windRegionList,
            valueField: 'id',
            textField : 'text',
            title     : 'Ветровой р-н',
            width     : 60,
        },
        {
            name      : 'PColdWarm',
            type      : 'select',
            items     : controller.coldWarmList,
            valueField: 'id',
            textField : 'text',
            title     : 'Холодное/Теплое',
            width     : 70,
        },
        {
            name        : 'PType',
            type        : 'select',
            items       : controller.typesList,
            valueField  : 'id',
            textField   : 'text',
            title       : 'Тип здания',
            width       : 50,
            cellRenderer: function (value) {
                let text = controller.typesList.hasOwnProperty(value)
                    ? controller.typesList[value].code
                    : '';
                return `<td><span>${text}</span></td>`;
            },
        },
        { name: 'PWidth', title: 'Ширина, м', width: 60 },
        { name: 'PLength', title: 'Длина, м', width: 50 },
        { name: 'PArea', title: 'Площадь, кв. м', width: 65 },
        { name: 'PHeight', title: 'Высота, м', width: 55 },
        { name: 'PWeight', title: 'Вес, т', width: 65 },
        { name: 'PPP', title: 'Наличие подкрановых путей', width: 85 },
        { name: 'PProjectFiles', title: 'Файлы проекта', width: 55, sorting: false },
        { name: 'PEstimateFiles', title: 'Файлы сметы', width: 50, sorting: false },
    ];

    let $grid = $('#projects-table');
    $grid.jsGrid({
        // height: 'calc(100vh - 300px)',
        width: '100%',

        filtering: true,
        sorting  : true,
        paging   : true,
        autoload : true,

        pageSize       : 12,
        pageButtonCount: 5,

        pageNextText : '&rarr;',
        pagePrevText : '&larr;',
        pageFirstText: '|&larr;',
        pageLastText : '&rarr;|',

        controller,
        fields,
    });

    $grid.on('input', '.jsgrid-filter-row input[type=text]', function () {
        $grid.jsGrid('search');
    });

    // $(function () {
    //     $('[data-toggle="tooltip"]').tooltip();
    // });

})();
