//==
//== Mobile menu
//== ======================================= ==//

$(function () {
    var $body            = $('body');
    var $openMenuButton  = $('[data-openmenu]');
    var $closeMenuButton = $('[data-closemenu]');
    var $menuPanel       = $('nav[data-offcanvas]');

    function toggleMenu(newState) {
        $menuPanel.toggleClass('isOpen', newState);
        $body.toggleClass('isMenuOpen', newState);
    }

    $openMenuButton.on('click', function () { toggleMenu(true); });
    $closeMenuButton.on('click', function () { toggleMenu(false); });

    // toggleMenu(true);

    var $menu = $('.navmenu > ul');

    if (!$menu.length) return;

    $menu.on('click', '.multimenu__toggle', function (e) {
        e.preventDefault();
        e.stopPropagation();
        var $item = $(this).closest('li');

        $item.toggleClass('open');
        $item.children('ul').slideToggle();
        $item.closest('ul').children('li').not($item).find('ul').slideUp();
    });

    $menu.find('.active').parent().addClass('open').children('ul').show();

    $('.icon-contact').on('click', function () {
        $('.mobile-contact').is(':visible')
            ? $('.mobile-contact').slideUp(300)
            : $('.mobile-contact').slideDown(300);
    });
});
